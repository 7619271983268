export const initialState = {
    cart: {
        queried: false,
        response: {}
    },
    depositSchedule: {
        queried: false,
        response: {}
    },
    form: {}
}