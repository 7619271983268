import { combineReducers } from "redux";
import cartReducer from "./cart";
import formReducer from "./form";

const rootReducer = () =>
  combineReducers({
    cart: cartReducer,
    form: formReducer
  });

export default rootReducer;
