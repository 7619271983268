import { initialState } from "../initialState";

const cartReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_CART_RESPONSE":
      state.cart.response = action.value;
      return state;
    case "SET_CART_STATUS":
      state.cart.queried = action.value;
      return state;
    case "SET_DEPSCH_RESPONSE":
      state.depositSchedule.response = action.value;
      return state;
    case "SET_DEPSCH_STATUS":
      state.depositSchedule.queried = action.value;
      return state;
    default:
      return state;
  }
};

export default cartReducer;
