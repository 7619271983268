import 'core-js/stable';
import 'regenerator-runtime/runtime';

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import { store } from './store/configureStore';

import * as serviceWorker from './serviceWorker';

import packageJson from '../package.json';

import {getGlobalIdentifier} from './utils/GlobalRetriever';

const prefixIdentifier = getGlobalIdentifier();
['system', 'config', 'currencyDatabase', 'hideTopbar'].forEach(name => {
    let prefixedVar = prefixIdentifier + name;
    localStorage.removeItem(prefixedVar);
});

global.appVersion = packageJson.version;

if (process.env.NODE_ENV === 'development') {
    console.log(`
     ____              _    _
    |  _ \\            | |  (_)
    | |_) | ___   ___ | | ___ _ __   __ _
    |  _ < / _ \\ / _ \\| |/ / | '_ \\ / _\` |
    | |_) | (_) | (_) |   <| | | | | (_| |
    |____/ \\___/ \\___/|_|\\_\\_|_| |_|\\__, |
                                     __/ |
                                    |___/
    version ${global.appVersion} - ${process.env.NODE_ENV} stage`
    );
} else {
    // let's just silence everything in prod, we don't really need it there
    console.log = () => true;
    // console.error = () => true;
    console.warn = () => true;
}

const App = React.lazy(() => import("./containers/App"));

ReactDOM.render((
    <Provider store={store}>
        <React.Suspense fallback={null}>
            <App />
        </React.Suspense>
    </Provider>
), document.getElementById('root'));

// ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
