import { initialState } from "../initialState";

const formReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_FORM_VALUE":
      state.form = action.value;
      return state;
    default:
      return state;
  }
};

export default formReducer;
