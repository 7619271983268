import EnvConfig from '../settings/current.json';

const getGlobalIdentifier = () => {
    const url = window.location.hostname;
    const path = window.location.pathname.split('/').length > 1 ? '/' + window.location.pathname.split('/')[1] : '';
    const customers = EnvConfig.customers;

    let identifier = false;

    customers.forEach((customer, idx) => {
        customer.urls.forEach((_url, jdx) => {
            if (_url === url + path || _url === url) {
                identifier = customer.name;
                if(customer.stage !== 'prod'){
                    identifier += customer.stage;
                }
            }
        });
    });

    return identifier;
}

export {
    getGlobalIdentifier
};